import React from "react"
import Layout from "components/layout"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    backgroundColor: "#162743",
  },
  center: {
    padding: theme.spacing(1),
    color: theme.palette.text.primary,
    //   background: theme.palette.background.paper,
    borderRadius: 6,
  },
}))

export default function NotFound() {
  const classes = useStyles()

  return (
    <Layout>
      <Box className={classes.root}>
        <Box className={classes.center}>
          <img src="404.gif" />
        </Box>
      </Box>
    </Layout>
  )
}
